@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Open Sans', sans-serif;
  }
}

.dark [type='checkbox'],
.dark [type='radio'] {
  background-color: theme('colors.zinc.700');
  --tw-shadow: '0 0 #FFFF';
}

.dark [type='checkbox']:focus,
.dark [type='radio']:focus {
  --tw-ring-offset-color: theme('colors.zinc.700');
}

.dark [type='checkbox']:checked,
.dark [type='radio']:checked {
  background-color: currentColor;
}

.dark .form-input,
.dark .form-textarea,
.dark .form-select,
.dark .form-multiselect,
.dark [type='text'],
.dark [type='email'],
.dark [type='url'],
.dark [type='password'],
.dark [type='number'],
.dark [type='date'],
.dark [type='datetime-local'],
.dark [type='month'],
.dark [type='search'],
.dark [type='tel'],
.dark [type='time'],
.dark [type='week'],
.dark [multiple],
.dark textarea,
.dark select {
  background-color: theme('colors.zinc.700');
  --tw-shadow: '0 0 #FFFF';
'&:focus':--tw-ring-offset-color: theme('colors.zinc.700');
}

.jsoneditor-poweredBy {
  display: none;
}
